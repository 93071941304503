// PopUp.js
import React from 'react';

const onTouch = () => {
    // Navigate to the new site
    window.location.href = 'https://sandcastles.framer.website';
};

const PopUp = ({ onClose }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-4">
            <div className="bg-white p-6 rounded-lg shadow-lg z-50 max-w-md w-full mx-auto relative">
                <button onClick={onClose} className="absolute top-0 right-0 m-4 p-2 text-white bg-custom-beige rounded-lg hover:bg-gray-400 transition duration-300">Close</button>
                <h1 className="text-lg font-semibold mb-4">Hi! We've updated our website.</h1>
                <p className="font-medium text-md md:text-xl text-amber-600 mb-5">Check out our new site for the latest updates and features.</p>
                <div className="flex justify-center">
                    <button onClick={onTouch} className="px-4 py-2 bg-custom-blue text-white rounded-lg hover:bg-blue-700 transition duration-300">Go to our new site</button>
                </div>
            </div>
        </div>
    );
};

export default PopUp;
