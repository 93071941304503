import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./landingpage";
import Navbar from "./navbar";
import Hero from "./landingtail";
import {Features} from "./features";
import {useEffect, useState} from "react";
import PopUp from "./popup";

function App() {
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);

    useEffect(() => {
        setIsPopUpVisible(true); // Show the pop-up when the component mounts
    }, []); // Empty dependency array means this effect runs only once after the initial render

    const handleClose = () => {
        setIsPopUpVisible(false); // Hide the pop-up
    };

    return (
        <div className="App">
            {isPopUpVisible && <PopUp onClose={handleClose} />}
            {/* Your site content */}
            <LandingPage />
            {/*<Features />*/}
        </div>
    );
}

export default App;
