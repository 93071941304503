import React from "react";
import {Button, Col, Container, Row} from 'react-bootstrap';
import './landing.css';
import logo from "./hor sand logo.png";

const IframeEmbed = ({ src, height, frameBorder, scrolling, style, testId }) => {
    return (
        <iframe
            src={src}
            data-test-id={testId}
            height={height}
            frameBorder={frameBorder}
            scrolling={scrolling}
            style={style}
        ></iframe>
    );
};
export const LandingPage = () => {


    return (


        <Container className="animateBg">
            <Row style={{width:"100vw", display:"flex", flexDirection:"row",
             padding: '15px 40px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
                backgroundColor:"rgba(238,247,251,0.7)"// Subtle shadow
            }}>

                <img src={logo} alt="Logo" style={styles.logo} />
                <div className="transparent" style={styles.div}>Launching in 2024</div>

            </Row>
            <Row>

            <Container className="centeredBox" >



                <Row style={{width:"75vw", alignItems:'center', alignContent:'center'}}>
                    <Col>

                        <h1 className="font-weight-medium mb-4">
                            Redefining <span className="text-primary">project<br/> management</span> for
                            <span className="text-primary"> builders</span>.
                        </h1>
                        <p className="subtitle">
                            Harnessing the power of Gen AI to streamline workflows,
                            enhance decision-making, and foster unparalleled collaboration.
                        </p>
                        <Row className="justify-content-md-center" style={{ width: "120%",
                            alignItems: 'center', alignContent: 'center' }}>
                            <Col md={12} className="d-flex justify-content-center align-items-center">

                            <div style={{ width: "50%", marginLeft:"18%", marginTop:"4%"}}> {/* Adjust maxWidth as per your design needs */}
                            <IframeEmbed
                                src="https://embeds.beehiiv.com/07f7fa34-8f78-48d4-a07e-816297cd7e94?slim=true"
                                data-test-id="beehiiv-embed"
                                height="52"
                                frameBorder="0"
                                scrolling="no"
                                style={{ width: '100%', margin: 0, borderRadius: '0px !important', backgroundColor: 'transparent' }}
                            />
                        </div>
                            </Col></Row>
                    </Col>
                </Row>
            </Container>
            </Row>
        </Container>

    );
};

const styles = {
    navbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 40px',
        backgroundColor: '#f0f0f0', // Example background color
    },
    logo: {
        height: '40px', // Adjust based on your logo's size
        width: 'auto',
        alignSelf:'flex-start'
    },
    div: {
        padding: '5px 5px',
        borderRadius: '20px',
        cursor: 'pointer',
        width: '160px',
        marginLeft:'900px',
        display: 'flex',
        alignItems: 'center',
        border: "0.8px solid #282c34",/* Adjust border thickness and color as needed */
        textAlign: 'center',
        color: '#6c757d'
    },
};
export default LandingPage;